<template>
  <v-container class="categories">
    <v-row>
      <v-col cols="6">
        <h1>Kategorien</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            :headers="headers"
            :items="categories"
            :hide-default-footer="true"
            :disable-pagination="true"
            sort-by="sort_name"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Neue Kategorie
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Kategorie</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              label="Name der Kategorie"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-checkbox
                              v-model="editedItem.show_in_app"
                              label="In App anzeigen"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="editedItem.word"
                              :items="words"
                              item-text="word"
                              item-value="id"
                              label="Wort"
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                  <v-list-item-action>
                                    <v-lazy
                                      ><FirebaseStorageImg
                                        v-if="
                                          item.symbol && item.symbol.filePath
                                        "
                                        :url="item.symbol.filePath"
                                        :width="50"
                                      ></FirebaseStorageImg
                                    ></v-lazy>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-row no-gutters align="center">
                                        <span>{{ item.word }}</span>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.id"
                              label="ID"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.sort_name"
                              label="Sortiername"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <FirebaseStorageImg
                              v-if="
                                editedItemWord &&
                                editedItemWord.symbol &&
                                editedItemWord.symbol.filePath
                              "
                              :url="editedItemWord.symbol.filePath"
                            ></FirebaseStorageImg>
                          </v-col>
                        </v-row>
                        <v-row v-if="editedItem.word !== ''">
                          <v-col cols="12" sm="6" md="4">
                            <v-btn @click="resetWord"
                              >Verbundenes Wort entfernen</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >{{ editedItem.name }} wirklich löschen?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.show_in_app`]="{ item }">
              <v-simple-checkbox
                v-model="item.show_in_app"
                disabled
                color="green"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

import { mapGetters } from "vuex";
import FirebaseStorageImg from "../components/FirebaseStorageImg.vue";

export default {
  name: "categories",
  components: { FirebaseStorageImg },

  data: function () {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Kategorie",
          align: "start",
          sortable: false,
          value: "name",
        },

        { text: "In App Anzeigen", value: "show_in_app", sortable: true },

        { text: "Actions", value: "actions", sortable: false },
      ],

      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
        sort_name: "",
        show_in_app: 0,
        word: "",
      },
      defaultItem: {
        id: "",
        name: "",
        sort_name: "",
        show_in_app: 0,
        word: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      categories: "categories/getList",
      words: "words/getList",
    }),
    editedItemWord() {
      if (this.editedItem && this.editedItem.word != "") {
        return this.words.find((w) => w.id === this.editedItem.word);
      }
      return {};
    },
  },
  watch: {},
  created: function () {},
  methods: {
    resetWord() {
      this.editedItem.word = "";
    },
    syncConfig() {
      this.$store.dispatch("debug/syncCategoriesConfig");
    },
    editItem(item) {
      this.editedIndex = this.categories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.categories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("categories/deleteWordCategory", {
        category: this.editedItem,
      });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      //   this.close();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedItem.id === "") {
        // create
        this.$store.dispatch("categories/createWordCategory", {
          category: this.editedItem,
        });
      } else {
        // save
        this.$store.dispatch("categories/updateWordCategory", {
          category: this.editedItem,
        });
      }
      this.close();
    },
  },
};
</script>
