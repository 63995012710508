var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"categories"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v("Kategorien")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.categories,"hide-default-footer":true,"disable-pagination":true,"sort-by":"sort_name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Neue Kategorie ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Kategorie")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Name der Kategorie"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-checkbox',{attrs:{"label":"In App anzeigen"},model:{value:(_vm.editedItem.show_in_app),callback:function ($$v) {_vm.$set(_vm.editedItem, "show_in_app", $$v)},expression:"editedItem.show_in_app"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.words,"item-text":"word","item-value":"id","label":"Wort"},scopedSlots:_vm._u([{key:"item",fn:function({ item, attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-lazy',[(
                                        item.symbol && item.symbol.filePath
                                      )?_c('FirebaseStorageImg',{attrs:{"url":item.symbol.filePath,"width":50}}):_vm._e()],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.word))])])],1)],1)],1)]}}]),model:{value:(_vm.editedItem.word),callback:function ($$v) {_vm.$set(_vm.editedItem, "word", $$v)},expression:"editedItem.word"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"ID","disabled":""},model:{value:(_vm.editedItem.id),callback:function ($$v) {_vm.$set(_vm.editedItem, "id", $$v)},expression:"editedItem.id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Sortiername","disabled":""},model:{value:(_vm.editedItem.sort_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "sort_name", $$v)},expression:"editedItem.sort_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(
                              _vm.editedItemWord &&
                              _vm.editedItemWord.symbol &&
                              _vm.editedItemWord.symbol.filePath
                            )?_c('FirebaseStorageImg',{attrs:{"url":_vm.editedItemWord.symbol.filePath}}):_vm._e()],1)],1),(_vm.editedItem.word !== '')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{on:{"click":_vm.resetWord}},[_vm._v("Verbundenes Wort entfernen")])],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.editedItem.name)+" wirklich löschen?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:`item.show_in_app`,fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"disabled":"","color":"green"},model:{value:(item.show_in_app),callback:function ($$v) {_vm.$set(item, "show_in_app", $$v)},expression:"item.show_in_app"}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }