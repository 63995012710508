<template>
  <v-container class="dictionaries">
    <v-row>
      <v-col cols="6">
        <h1>Releases</h1>
      </v-col>
    </v-row>

    <v-sheet>
      <v-data-table
        :headers="headers"
        :items="history"
        :hide-default-footer="true"
        :disable-pagination="true"
        sort-by="sort_name"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Neues Release veröffentlichen
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Release</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        Möchtest du deine Änderungen veröffentlichen?
                      </v-col>
                    </v-row>
                    <v-row v-if="saving">
                      <v-col cols="6"> Wird veröffentlicht... </v-col>
                      <v-col cols="6">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions v-if="saving == false">
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"> Nö </v-btn>
                  <v-btn color="blue darken-1" text @click="rebuildWords">
                    Ja
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Wörterbuch "{{ editedItem.name }}" wirklich
                  löschen?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-icon color="green" v-if="item.hash == release.hash"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon color="red" v-else>mdi-close</v-icon>
        </template>
        <template v-slot:[`item.updated`]="{ item }">
          {{ item.updated | formatDate }} Uhr
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

import { mapGetters } from "vuex";

export default {
  name: "releases",
  components: {},
  data: function () {
    return {
      dialog: false,
      saving: false,
      dialogDelete: false,
      headers: [
        {
          text: "Aktiv",
          align: "start",
          sortable: false,
          value: "active",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "hash",
        },
        {
          text: "Filename",
          align: "start",
          sortable: false,
          value: "path",
        },
        {
          text: "Release Date",
          align: "start",
          sortable: false,
          value: "updated",
        },

        // { text: "In App Anzeigen", value: "show_in_app", sortable: true },

        { text: "", value: "actions", sortable: false },
      ],

      editedIndex: -1,
      editedItem: {
        hash: "",
        path: "",
        updated: 0,
      },
      defaultItem: {
        hash: "",
        path: "",
        updated: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      release: "releases/get",
      history: "releases/getHistory",
      error: "releases/getError",
    }),
  },
  watch: {},
  created: function () {
    // this.$store.dispatch("dictionaries/fetchConfigList");
  },
  methods: {
    editItem() {},

    deleteItem() {},

    deleteItemConfirm() {},

    close() {
      this.dialog = false;
      this.saving = false;
    },
    closeDelete() {},
    save() {},
    async rebuildWords() {
      this.saving = true;
      await this.$store.dispatch("words/rebuildWordsfile");
      this.close();
    },
  },
};
</script>
